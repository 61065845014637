import {
  ArrowRight,
  BeerStein,
  BowlSteam,
  Champagne,
  Hamburger,
  IntersectThree,
  Popcorn,
} from '@phosphor-icons/react';
import Link from 'next/link';
import type { ReactElement } from 'react';

import { Title } from '../common/Title';

interface BusinessProp {
  title: string;
  desc: string;
  icon: ReactElement;
  url: string;
}

const businessTypes: BusinessProp[] = [
  {
    title: 'Quán ăn truyền thống',
    desc: 'Đơn hàng được đồng bộ chính xác, giảm thiểu sai sót trong vận hành',
    icon: <BowlSteam size={32} weight="regular" />,
    url: '/',
  },
  {
    title: 'Quán lẩu - nhậu',
    desc: 'QR Gọi món - tăng trải nghiệm của khách hàng và nâng tầm thương hiệu',
    icon: <BeerStein size={32} weight="regular" />,
    url: '/',
  },
  {
    title: 'Quán ốc - ăn vặt',
    desc: 'Quản lý thu chi chặt chẽ, thống kê chính xác kết quả kinh doanh',
    icon: <Hamburger size={32} weight="regular" />,
    url: '/',
  },
  {
    title: 'Quán cà phê - trà sữa',
    desc: 'Mọi thứ được gói gọn trong chiếc điện thoại thông minh của bạn',
    icon: <Champagne size={32} weight="regular" />,
    url: '/',
  },
  // {
  //   title: 'Quán Cà phê',
  //   desc: 'Mọi thứ được gói gọn trong chiếc điện thoại thông minh của bạn',
  //   icon: <Coffee size={32} weight="regular" />,
  //   url: '/',
  // },
  {
    title: 'Quán bán mang đi',
    desc: 'Trang bán hàng miễn phí giúp khách hàng dễ dàng đặt đơn ngay tại nhà',
    icon: <Popcorn size={32} weight="regular" />,
    url: '/',
  },
  // {
  //   title: 'Nhà hàng truyền thống',
  //   desc: 'Từ các mô hình vừa và nhỏ như quán ăn, quán cà phê, cho đến các mô hình lớn như nhà hàng, buffet, chuỗi FnB',
  //   icon: <CookingPot size={32} weight="regular" />,
  //   url: '/',
  // },
  // {
  //   title: 'Nhà hàng Buffet',
  //   desc: 'QR gọi món tiện lợi, đưa trải nghiệm khách hàng lên tầng cao mới',
  //   icon: <Shrimp size={32} weight="regular" />,
  //   url: '/',
  // },
  {
    title: 'Mô hình chuỗi FnB',
    desc: 'Quản lý đồng thời nhiều cửa hàng và kiểm soát kinh doanh tốt hơn',
    icon: <IntersectThree size={32} weight="regular" />,
    url: '/',
  },
];

export const SolutionSection = () => {
  return (
    <section id="solutions" className="section section-container max-sm:px-0">
      <Title
        title="Zump đáp ứng cho mọi mô hình kinh doanh trong lĩnh vực FnB"
        description="Từ các mô hình vừa và nhỏ như quán ăn, quán cà phê, cho đến các mô hình lớn như nhà hàng, buffet, chuỗi FnB"
        className="m-auto max-w-3xl text-center max-sm:px-8"
      />
      <div className="mx-auto mt-20 grid grid-cols-3 gap-2 max-sm:px-2 md:gap-5">
        {businessTypes.map((business, i) => (
          <Link
            key={i}
            href={business.url}
            className="group flex max-w-80 flex-col gap-5 rounded-2xl border-2 p-2 shadow transition-colors will-change-color hover:border-primary-400 md:p-5 md:shadow-lg"
          >
            <div className="flex grow flex-col gap-1 md:gap-5">
              <div className="mx-auto size-16 rounded-lg bg-none p-4 text-primary-400 transition-colors will-change-color md:mx-0 md:bg-primary-50 md:group-hover:bg-primary-400 md:group-hover:text-white">
                {business.icon}
              </div>
              <h3 className="max-sm:text-sm md:text-left">{business.title}</h3>
              <p className="hidden md:block md:text-left">{business.desc}</p>
            </div>
            <div className="hidden items-center gap-2 text-primary-500 md:flex">
              <span className="text-primary-500">Tìm hiểu thêm</span>
              <ArrowRight />
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};
