import React from 'react';

import { HeroContent } from '../widgets/HeroContent';
import HeroHighlights from '../widgets/HeroHighlights';

export default function HeroSection() {
  return (
    <section className="section bg-primary-50 bg-gradient-to-br from-white from-50% to-primary-100 pb-0">
      <div className="section-container section z-10 grid size-full grid-cols-1 flex-col gap-10 px-0 py-8 md:gap-16 lg:grid-cols-2 xl:justify-between xl:gap-20">
        <HeroContent />
        <HeroHighlights />
        {/* <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 1.5,
            ease: 'easeOut',
          }}
        >
          <div className="relative aspect-square size-full rounded-r-3xl [mask-image:linear-gradient(to_left,#FFF_20%,transparent_95%)] lg:mt-0">
            <Image
              src={HeroImage}
              alt="hero-image"
              className="object-cover"
              fill
            />
          </div>
        </motion.div> */}
      </div>
    </section>
  );
}
