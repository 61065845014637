import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React from 'react';

export default function Footer() {
  const { t } = useTranslation('common');
  return (
    <footer
      id="contact"
      className="bg-gradient-to-tr from-white to-primary-100 font-light text-primary-950"
    >
      <div>
        <div className="section section-container gap-5 py-8">
          <div className="grid w-full gap-12 pb-4 sm:grid-cols-2 xl:gap-24">
            <div className="flex w-full flex-col gap-3">
              <p>CÔNG TY TNHH GIẢI PHÁP PHẦN MỀM RIZERS - SOFT</p>
              <p>
                <b>Mã số thuế: </b> 0317652639
              </p>
              <p>
                <b>Địa chỉ: </b>72/19/8/29 Đường số 4, khu phố 6 , Phường Hiệp
                Bình Phước, Thành phố Thủ Đức, Thành phố Hồ Chí Minh, Việt Nam
              </p>
              <p>
                <b>Hotline hỗ trợ khách hàng: </b>0374 828 046
              </p>
              <p>
                <b>Email hỗ trợ kỹ thuật: </b>support@rizerssoft.com
              </p>
            </div>
            <div className="w-full sm:justify-self-center">
              <div className="box-border flex w-full flex-col items-center gap-2 sm:items-end">
                <Link href="/">{t('footer.solutions')}</Link>
                <Link href="/#features">{t(`footer.features`)}</Link>
                <Link href="/#pricing">{t(`footer.pricing`)}</Link>
                <Link href="/">{t(`footer.about`)}</Link>
                <Link href="/privacy-policy">{t(`footer.privacy-policy`)}</Link>
                <Link href="/terms-and-conditions">
                  {t(`footer.term-and-conditions`)}
                </Link>
              </div>
            </div>
          </div>
          <div className="m-auto text-center">
            <div className="text-primary-950 sm:text-sm">
              <p className="text-center">©2023 Zump.vn. All Rights Reserved</p>
            </div>
            <div className="text-primary-950 sm:text-sm">
              <a
                href="https://www.rizerssoft.com/"
                aria-label="Powered by Rizerssoft Ltd"
                className="underline"
                target="_blank"
              >
                <p className="text-center">Powered by Rizerssoft Ltd</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
