import Image from 'next/image';

import { ChartIcon, CheckListIcon, FeatureIcon, ThunderIcon } from '@/assets';
import { Title } from '@/components/common/Title';
import type { UtilityProps } from '@/components/common/Utility';

import HeroHighlights from '../widgets/HeroHighlights';

const utilities: UtilityProps[] = [
  {
    icon: <Image src={ChartIcon} alt="" />,
    title: 'Cung cấp thông tin và phân tích nhu cầu',
    description:
      'Cung cấp thông tin chi tiết và phân tích sâu sắc giúp hiểu rõ hơn về nhu cầu của khách hàng và điều chỉnh chiến lược kinh doanh một cách linh hoạt và hiệu quả.',
  },
  {
    icon: <Image src={ThunderIcon} alt="" />,
    title: 'Tối ưu hóa',
    description:
      'Tối ưu hoá mọi khía cạnh trong hoạt động kinh doanh, bao gồm quản lý đơn hàng, thực đơn, nhân viên và thống kê hiệu suất',
  },
  {
    icon: <Image src={FeatureIcon} alt="" />,
    title: 'Dịch vụ chuyên nghiệp và tiện lợi',
    description:
      'Dịch vụ chuyên nghiệp và tiện lợi của Zump đảm bảo trải nghiệm tốt nhất cho khách hàng.',
  },
  {
    icon: <Image src={CheckListIcon} alt="" />,
    title: 'Công cụ và tính năng tiên tiến',
    description:
      'Zump cung cấp các công cụ và tính năng tiên tiến giúp doanh nghiệp nắm bắt được xu hướng và nhu cầu của thị trường',
  },
];

export const UtilitiesSection = () => {
  return (
    <section id="utilities" className="section section-container">
      <Title
        title="Lợi ích khi sử dụng giải pháp"
        description="Giải pháp quản lý quán ăn, cửa hàng đơn giản, hiệu quả, đáp ứng mọi nhu cầu, được tin dùng bởi hơn 4.000 cửa hàng."
        className="m-auto max-w-3xl text-center"
      />
      <div className="relative mt-10 w-full">
        <HeroHighlights />
      </div>
      {/* <div className="mt-16 flex flex-col gap-8 overflow-x-hidden overflow-y-visible md:flex-row md:justify-between">
        <motion.div
          className="md:w-72"
          initial="offscreen"
          whileInView="onscreen"
        >
          {utilities.slice(0, 2).map((utility, key) => (
            <Utility
              icon={utility.icon}
              title={utility.title}
              description={utility.description}
              key={key}
              className="mt-10 flex w-full flex-col text-center md:items-start md:text-start md:first:mt-0"
              variants={{
                offscreen: { x: -300, opacity: 0 },
                onscreen: {
                  opacity: 1,
                  x: 0,
                  transition: {
                    type: 'spring',
                    duration: 1.5 + key / 3,
                  },
                },
              }}
            />
          ))}
        </motion.div>
        <motion.div
          className="order-first mt-20 md:order-none md:mt-0"
          initial="offscreen"
          whileInView="onscreen"
          variants={{
            offscreen: { y: 0, opacity: 0 },
            onscreen: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 1,
                ease: 'easeOut',
              },
            },
          }}
        >
          <Image
            src={UtilitiesImage}
            alt="utilities image"
            className="m-auto object-contain transition-all duration-300 hover:drop-shadow-[0_10px_10px_rgba(106,190,128,0.3)]"
            unoptimized
          />
        </motion.div>
        <motion.div
          className="md:w-72"
          initial="offscreen"
          whileInView="onscreen"
        >
          {utilities.slice(2).map((utility, key) => (
            <Utility
              icon={utility.icon}
              title={utility.title}
              description={utility.description}
              key={key}
              className="mt-10 flex w-full flex-col items-center text-center md:items-start md:text-start md:first:mt-0"
              variants={{
                offscreen: { x: 300, opacity: 0 },
                onscreen: {
                  opacity: 1,
                  x: 0,
                  transition: {
                    type: 'spring',
                    delay: 0.5,
                    duration: 1.5 + key / 3,
                  },
                },
              }}
            />
          ))}
        </motion.div>
      </div> */}
    </section>
  );
};
