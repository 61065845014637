import 'swiper/css';

import {
  ChartLineUp,
  CloudCheck,
  IntersectThree,
  Lightning,
  QrCode,
  ShoppingBag,
} from '@phosphor-icons/react';
import { motion } from 'framer-motion';
import Image from 'next/image';
import type { ReactElement } from 'react';
import { useState } from 'react';
import { Autoplay, Thumbs } from 'swiper/modules';
import type { SwiperClass } from 'swiper/react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { FeatureManageImage } from '@/assets';

interface HighlightProps {
  title: string;
  icon: ReactElement;
  desc: string;
  image: any;
  bgClassName: string;
  textClassName: string;
  borderClassName: string;
}

const highlights: HighlightProps[] = [
  {
    title: 'Đồng bộ\ntức thì',
    icon: <CloudCheck />,
    desc: 'Giải pháp quản lý được thiết kế dành riêng cho Doanh nghiệp/Cửa hàng vừa và nhỏ có nhu cầu số hoá và thống kê số lượng đơn hàng, nguồn tiền và xu hướng các món ăn tại cửa hàng',
    image: FeatureManageImage,
    bgClassName: 'bg-amber-50',
    textClassName: 'text-amber-700',
    borderClassName: 'border-amber-700/40',
  },
  {
    title: 'Thanh toán\nsiêu nhanh',
    icon: <Lightning />,
    desc: 'Giải pháp quản lý được thiết kế dành riêng cho Doanh nghiệp/Cửa hàng vừa và nhỏ có nhu cầu số hoá và thống kê số lượng đơn hàng, nguồn tiền và xu hướng các món ăn tại cửa hàng',
    image: FeatureManageImage,
    bgClassName: 'bg-green-50',
    textClassName: 'text-green-700',
    borderClassName: 'border-green-700/40',
  },
  {
    title: 'QR gọi món\ntiện lợi',
    icon: <QrCode />,
    desc: 'Giải pháp quản lý được thiết kế dành riêng cho Doanh nghiệp/Cửa hàng vừa và nhỏ có nhu cầu số hoá và thống kê số lượng đơn hàng, nguồn tiền và xu hướng các món ăn tại cửa hàng',
    image: FeatureManageImage,
    bgClassName: 'bg-violet-50',
    textClassName: 'text-violet-700',
    borderClassName: 'border-violet-700/40',
  },
  {
    title: 'Trang bán hàng\nmiễn phí',
    icon: <ShoppingBag />,
    desc: 'Giải pháp quản lý được thiết kế dành riêng cho Doanh nghiệp/Cửa hàng vừa và nhỏ có nhu cầu số hoá và thống kê số lượng đơn hàng, nguồn tiền và xu hướng các món ăn tại cửa hàng',
    image: FeatureManageImage,
    bgClassName: 'bg-blue-50',
    textClassName: 'text-blue-700',
    borderClassName: 'border-blue-700/40',
  },
  {
    title: 'Báo cáo\nchi tiết',
    icon: <ChartLineUp />,
    desc: 'Giải pháp quản lý được thiết kế dành riêng cho Doanh nghiệp/Cửa hàng vừa và nhỏ có nhu cầu số hoá và thống kê số lượng đơn hàng, nguồn tiền và xu hướng các món ăn tại cửa hàng',
    image: FeatureManageImage,
    bgClassName: 'bg-cyan-50',
    textClassName: 'text-cyan-700',
    borderClassName: 'border-cyan-700/40',
  },
  {
    title: 'Tất cả\ntrong một',
    icon: <IntersectThree />,
    desc: 'Giải pháp quản lý được thiết kế dành riêng cho Doanh nghiệp/Cửa hàng vừa và nhỏ có nhu cầu số hoá và thống kê số lượng đơn hàng, nguồn tiền và xu hướng các món ăn tại cửa hàng',
    image: FeatureManageImage,
    bgClassName: 'bg-orange-50',
    textClassName: 'text-orange-700',
    borderClassName: 'border-orange-700/40',
  },
];

export default function HeroHighlights() {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass>();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 1.5,
        ease: 'easeOut',
      }}
      className="relative flex flex-col gap-4 p-8"
    >
      <h2>
        <strong>Với những tính năng vượt trội</strong>
      </h2>
      <div className="flex gap-1 overflow-x-auto">
        <Swiper
          modules={[Thumbs]}
          freeMode
          slidesPerView="auto"
          watchSlidesProgress
          onSwiper={setThumbsSwiper}
        >
          {highlights.map((highlight, i) => (
            <SwiperSlide key={i} className="!w-40 p-1">
              <div
                key={i}
                className={`button-shinny flex flex-col items-center gap-2 rounded-lg border-2 px-4 py-2 transition-transform duration-500 hover:scale-105 hover:cursor-pointer hover:after:rounded-lg hover:after:bg-gray-200/20 ${highlight.bgClassName} ${highlight.borderClassName} ${highlight.textClassName}`}
              >
                <div>{highlight.icon}</div>
                <p className="whitespace-pre text-center text-sm font-medium text-inherit sm:text-base">
                  {highlight.title}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="relative w-full">
        <Swiper
          modules={[Autoplay, Thumbs]}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          watchSlidesProgress
          speed={700}
          spaceBetween={24}
          thumbs={{ swiper: thumbsSwiper, autoScrollOffset: 1 }}
        >
          {highlights.map((highlight, i) => (
            <SwiperSlide key={i}>
              <div
                className={`flex flex-col rounded-3xl ${highlight.bgClassName}`}
              >
                <div className="p-5">
                  <h4
                    className={`flex items-center gap-2 text-left ${highlight.textClassName}`}
                  >
                    {highlight.icon} {highlight.title}
                  </h4>
                  <p className="mt-4 text-left">{highlight.desc}</p>
                </div>
                <div>
                  <Image
                    src={highlight.image}
                    alt={highlight.title}
                    className="mx-auto aspect-square size-full object-contain md:size-1/2"
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </motion.div>
  );
}
