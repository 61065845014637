import { Star } from '@phosphor-icons/react';
import type { StaticImport } from 'next/dist/shared/lib/get-img-props';
import Image from 'next/image';
import React from 'react';

export interface CardTestimionialProps {
  point: 0 | 1 | 2 | 3 | 4 | 5;
  review: string;
  avatar: StaticImport;
  name: string;
  role: string;
}

export const CardTestimionial = ({
  review,
  avatar,
  name,
  role,
}: CardTestimionialProps) => {
  return (
    <div className="mt-4 flex w-full flex-col rounded-3xl bg-white px-6 py-9 shadow-lg transition-transform duration-500 hover:-translate-y-4">
      <div className="m-auto mb-8 flex">
        <Star weight="fill" className="text-amber-500" />
        <Star weight="fill" className="text-amber-500" />
        <Star weight="fill" className="text-amber-500" />
        <Star weight="fill" className="text-amber-500" />
        <Star weight="fill" className="text-amber-500" />
      </div>
      <div className="mb-8 font-light text-primary-950">{review}</div>
      <div className="flex items-center">
        <div className="relative mr-2 size-8 overflow-hidden rounded-full bg-gray-300">
          <Image src={avatar} alt="user" width={64} height={64} priority />
        </div>
        <div>
          <div className="text-sm font-semibold leading-5 text-primary-950">
            {name}
          </div>
          <p className="text-left text-xs leading-4 text-primary-950">{role}</p>
        </div>
      </div>
    </div>
  );
};
