import { useTranslation } from 'next-i18next';
import type { ButtonHTMLAttributes } from 'react';
import React, { forwardRef } from 'react';

export type IconButtonVariant =
  | 'primary'
  | 'secondary'
  | 'primaryTransparent'
  | 'secondaryTransparent'
  | 'redTransparent';

export interface IconButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  icon?: JSX.Element | React.ReactNode;
  variant?: IconButtonVariant;
  disableTranslate?: boolean;
  keepTextHeight?: boolean;
}

const variants = {
  primary: {
    bg: 'bg-primary-400 disabled:bg-primary-200',
    text: 'text-white',
  },
  secondary: {
    bg: 'bg-white border-2 border-primary-50/40',
    text: 'text-primary-500 hover:text-primary-600 active:text-primary-700 disabled:text-primary-100',
  },
  primaryTransparent: {
    bg: 'bg-transparent border-transparent !shadow-none p-1.5',
    text: 'text-primary-500',
  },
  secondaryTransparent: {
    bg: 'bg-transparent border-transparent !shadow-none p-1.5',
    text: 'text-white',
  },
  redTransparent: {
    bg: 'bg-transparent border-transparent !shadow-none p-1.5',
    text: 'text-red-600',
  },
};

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      label,
      disableTranslate = false,
      keepTextHeight = false,
      icon,
      variant = 'primary',
      className,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation('common');
    return (
      <button
        ref={ref}
        className={`flex flex-row items-center justify-center gap-2 rounded-full px-6 py-4 shadow-md transition duration-200 ease-in-out focus:outline-none focus:ring-0 ${variants[variant].bg} ${className}`}
        {...props}
      >
        {!(!keepTextHeight && label == null) && (
          <span
            className={`pointer-events-none inline-block w-max grow text-center ${variants[variant].text}`}
          >
            {!disableTranslate && label ? t(`buttons.${label}`) : label}
          </span>
        )}
        {icon && (
          <span className={`pointer-events-none ${variants[variant].text}`}>
            {icon}
          </span>
        )}
      </button>
    );
  }
);

export default IconButton;
