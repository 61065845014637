import AppStoreBtn from '@/public/assets/images/app-store-btn.svg';
import ChartIcon from '@/public/assets/images/chart.svg';
import CheckedImage from '@/public/assets/images/checked.svg';
import CheckListIcon from '@/public/assets/images/checklist.svg';
import CustomerAvatar1 from '@/public/assets/images/customer_bach_viet.webp';
import CustomerAvatar2 from '@/public/assets/images/customer_hoang_thinh.webp';
import CustomerAvatar4 from '@/public/assets/images/customer_minh_tuan.webp';
import CustomerAvatar3 from '@/public/assets/images/customer_viet_hoang.webp';
import DirectionIcon from '@/public/assets/images/direction.svg';
import EmptyImage from '@/public/assets/images/empty.webp';
import EmptyAvatarImage from '@/public/assets/images/empty-avatar.webp';
import ExpressIcon from '@/public/assets/images/express.svg';
import FacebookLogo from '@/public/assets/images/facebook_logo.png';
import FeatureIcon from '@/public/assets/images/feature.svg';
import FeatureManageImage from '@/public/assets/images/features-manage.png';
import GgPlayBtn from '@/public/assets/images/gg-play-btn.svg';
import GradientAccountImage from '@/public/assets/images/gradient_account.svg';
import GradientIEImage from '@/public/assets/images/gradient_income_expenses.svg';
import GradientProductsImage from '@/public/assets/images/gradient_products.svg';
import GradientStaffsImage from '@/public/assets/images/gradient_staffs.svg';
import GradientStatisticsImage from '@/public/assets/images/gradient_statistics.svg';
import GradientStoreImage from '@/public/assets/images/gradient_store.svg';
import GradientStoreFrontImage from '@/public/assets/images/gradient_storefront.svg';
import HeroBackground from '@/public/assets/images/hero-background.webp';
import HeroImage from '@/public/assets/images/hero-image.jpeg';
import LoadingImage from '@/public/assets/images/loading.json';
import MessageIcon from '@/public/assets/images/message.svg';
import MetaImage from '@/public/assets/images/meta-image.jpg';
import ThunderIcon from '@/public/assets/images/thunder.svg';
import UtilitiesImage from '@/public/assets/images/utilities-image.webp';
import ZaloLogo from '@/public/assets/images/zalo_logo.webp';
import LogoImage from '@/public/zump_logo_primary.svg';
import LogoWhiteImage from '@/public/zump_logo_white.svg';

export {
  AppStoreBtn,
  ChartIcon,
  CheckedImage,
  CheckListIcon,
  CustomerAvatar1,
  CustomerAvatar2,
  CustomerAvatar3,
  CustomerAvatar4,
  DirectionIcon,
  EmptyAvatarImage,
  EmptyImage,
  ExpressIcon,
  FacebookLogo,
  FeatureIcon,
  FeatureManageImage,
  GgPlayBtn,
  GradientAccountImage,
  GradientIEImage,
  GradientProductsImage,
  GradientStaffsImage,
  GradientStatisticsImage,
  GradientStoreFrontImage,
  GradientStoreImage,
  HeroBackground,
  HeroImage,
  LoadingImage,
  LogoImage,
  LogoWhiteImage,
  MessageIcon,
  MetaImage,
  ThunderIcon,
  UtilitiesImage,
  ZaloLogo,
};
