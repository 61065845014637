import '@/styles/global.css';

import { IconContext } from '@phosphor-icons/react';
import type { AppProps } from 'next/app';
import { Inter } from 'next/font/google';
import { appWithTranslation } from 'next-i18next';

const fontFamily = Inter({
  subsets: ['latin'],
  display: 'block',
  variable: '--font-inter',
});

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <main className={`${fontFamily.variable} font-sans`}>
      <IconContext.Provider value={{ weight: 'bold', size: 24 }}>
        <Component {...pageProps} />
      </IconContext.Provider>
    </main>
  );
};

export default appWithTranslation(MyApp);
