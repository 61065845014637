import { ArrowRight, Play } from '@phosphor-icons/react';
import { motion } from 'framer-motion';

import IconButton from '../common/IconButton';
import AppStore from './AppStore';
import GooglePlay from './GooglePlay';

export const HeroContent = () => {
  return (
    <motion.div
      className="flex flex-1 flex-col items-center gap-5 p-8 lg:items-start lg:gap-10"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 1,
        ease: 'ease',
      }}
    >
      <h1 className="whitespace-pre">
        {`Nền tảng\nquản lý bán hàng FnB\ntối ưu nhất - Zump`}
      </h1>
      <h3>
        Tiên phong áp dụng đột phá mới trong lĩnh vực quản lý bán hàng FnB
      </h3>
      <div className="flex w-fit flex-col items-center gap-5 lg:gap-10">
        <div className="flex gap-4 md:gap-5 lg:gap-8">
          <IconButton
            label="Tư vấn ngay"
            variant="primary"
            disableTranslate
            icon={<ArrowRight />}
            className="button-shinny !px-4 !py-2 text-sm md:!px-6 md:!py-3 md:text-base lg:!px-8 lg:!py-4 lg:text-xl lg:font-semibold"
            onClick={() => window.$chatwoot.toggle('open')}
          />
          <IconButton
            label="Khám phá Zump"
            variant="secondary"
            disableTranslate
            icon={<Play weight="duotone" className="text-primary-500" />}
            className="button-shinny button-ping !px-4 !py-2 text-sm md:!px-6 md:!py-3 md:text-base lg:!px-8 lg:!py-4 lg:text-xl lg:font-semibold"
          />
        </div>
        <div className="flex w-full items-center before:m-2 before:flex-1 before:border-b before:border-primary-200 before:content-['_'] after:m-2 after:flex-1 after:border-b after:border-primary-200 after:content-['_']">
          <p>Hoặc trải nghiệm ứng dụng miễn phí</p>
        </div>
        <div className="flex gap-2 md:gap-4 lg:gap-5">
          <AppStore />
          <GooglePlay />
        </div>
      </div>
    </motion.div>
  );
};
