import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { GgPlayBtn } from '@/assets';

export default function GooglePlay() {
  return (
    <Link
      href="https://play.google.com/store/apps/details?id=com.rizerssoft.hangrong&pcampaignid=web_share"
      target="_blank"
      className="button-shinny hover:after:rounded-lg"
    >
      <Image
        src={GgPlayBtn}
        alt="google_play_btn"
        className="h-12 w-auto md:h-14 lg:h-16"
      />
    </Link>
  );
}
