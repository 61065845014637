import { CaretUp } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';

import IconButton from '../common/IconButton';

const MoveToTop = () => {
  const [isGoToTop, setIsGoToTop] = useState<boolean>(false);

  const scrollHandler = () => {
    if (window.scrollY > 1024) {
      setIsGoToTop(true);
    } else {
      setIsGoToTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  return (
    <IconButton
      icon={<CaretUp />}
      className={`circle-button-shinny !size-14 rounded-full !p-0 transition-all duration-500 ${
        isGoToTop ? 'opacity-100' : 'opacity-0'
      }`}
      type="button"
      onClick={() => window.scroll({ top: 0 })}
    />
  );
};

export default MoveToTop;
