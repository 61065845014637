import Image from 'next/image';
import Link from 'next/link';

import { GradientStoreImage } from '@/assets';

import IconButton from '../common/IconButton';

const OpenStore = () => {
  return (
    <Link href="/#cta" className="w-full sm:w-auto">
      <IconButton
        label="freeTrial"
        variant="primary"
        icon={
          <div className="relative size-10 rounded-full bg-primary-50 p-2">
            <Image src={GradientStoreImage} alt="free-trial" />
          </div>
        }
        className="button-shinny w-full gap-4 !py-2 !pr-2 text-lg sm:w-auto"
      />
    </Link>
  );
};

export default OpenStore;
