import type { StaticImageData } from 'next/image';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { LogoImage } from '@/assets';

export interface HeaderLogoProps {
  customLogo?: string | StaticImageData;
  customName?: string;
}

export default function HeaderLogo({ customLogo }: HeaderLogoProps) {
  return (
    <Link href="/" className="flex flex-row items-center text-inherit" shallow>
      <Image
        src={customLogo ?? LogoImage}
        alt="logo"
        className="h-14 w-auto rounded-lg object-cover py-2"
        priority={true}
        crossOrigin="anonymous"
      />
    </Link>
  );
}
