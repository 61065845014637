/* eslint-disable class-methods-use-this */
import { ChatCircle } from '@phosphor-icons/react';
import React from 'react';

import IconButton from '../common/IconButton';

class ChatwootWidget extends React.Component {
  componentDidMount() {
    window.chatwootSettings = {
      style: 'flat',
      hideMessageBubble: true,
    };

    (function (d, t) {
      const BASE_URL = 'https://crm.rizerssoft.app';
      const g: any = d.createElement(t);
      const s: any = d.getElementsByTagName(t)[0];
      g.src = `${BASE_URL}/packs/js/sdk.js`;
      g.defer = true;
      g.async = true;
      s.parentNode.insertBefore(g, s);
      g.onload = function () {
        window.chatwootSDK.run({
          websiteToken: 'JpQbLx8usuZkoAhaCQNfbnGr',
          baseUrl: BASE_URL,
        });
      };
    })(document, 'script');
  }

  render() {
    return (
      <IconButton
        icon={<ChatCircle weight="fill" />}
        className="circle-button-shinny button-ping !size-14 rounded-full !p-0 transition-all duration-500"
        type="button"
        onClick={() => window.$chatwoot.toggle()}
      />
    );
  }
}

export default ChatwootWidget;
