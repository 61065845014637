export interface HeaderMenu {
  title: string;
  url?: string;
  action?: () => void;
  subMenus?: {
    title: string;
    url?: string;
    action?: () => void;
  }[];
}

export const menu: HeaderMenu[] = [
  {
    title: 'solutions',
    url: '/',
  },
  {
    title: 'features',
    url: '/#features',
    // subMenus: [
    //   {
    //     title: 'utilities',
    //     url: '/#utilities',
    //   },
    //   {
    //     title: 'features',
    //     url: '/#features',
    //   },
    // ],
  },
  {
    title: 'pricing',
    url: '/#pricing',
  },
  {
    title: 'docs',
    url: '/docs/',
  },
  {
    title: 'about',
    url: '/',
  },
];

export const getLanguageFlag = (locale?: string) => {
  switch (locale) {
    case 'vi':
      return '🇻🇳';
    default:
      return '🇬🇧';
  }
};
