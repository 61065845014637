import type { HTMLProps } from 'react';

interface TitleProps extends HTMLProps<HTMLDivElement> {
  tag?: string;
  title: string;
  description?: string;
}

export const Title = ({ tag, title, description, ...props }: TitleProps) => {
  return (
    <div {...props}>
      {tag && (
        <div className="mb-2 text-center font-medium text-primary-500">
          {tag}
        </div>
      )}
      <h2 className="text-center">{title}</h2>
      {description && <h3 className="mt-2 text-center">{description}</h3>}
    </div>
  );
};
