import type { HTMLMotionProps } from 'framer-motion';
import { motion } from 'framer-motion';
import React from 'react';

export interface UtilityProps extends HTMLMotionProps<'div'> {
  icon: JSX.Element | React.ReactNode | any;
  title: string;
  description: string;
}

export const Utility = ({
  icon,
  title,
  description,
  ...props
}: UtilityProps) => {
  return (
    <motion.div {...props}>
      <div className="mb-4 flex w-full items-center gap-4">
        <div className="shrink-0">{icon}</div>
        <h4 className="text-left">{title}</h4>
      </div>
      <p className="text-left">{description}</p>
    </motion.div>
  );
};
