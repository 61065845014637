import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'swiper/css';

import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  CustomerAvatar1,
  CustomerAvatar2,
  CustomerAvatar3,
  CustomerAvatar4,
} from '@/assets';

import type { CardTestimionialProps } from '../common/CardTestimionial';
import { CardTestimionial } from '../common/CardTestimionial';
import { Title } from '../common/Title';

const reviews: CardTestimionialProps[] = [
  {
    point: 5,
    review: `Tôi biết đến Zump từ những ngày đầu tiên làm quán. Zump đã giúp tôi tối ưu hơn quy trình phục vụ khách hàng, qua đó nhận được
    sự hài lòng và đánh giá cao từ các khách hàng.`,
    avatar: CustomerAvatar1,
    name: 'Nguyễn Bách Việt',
    role: 'Chủ quán ăn',
  },
  {
    point: 5,
    review: `Trước khi biết đến Zump tôi luôn phải đau đầu mỗi tuần với các hóa đơn giấy từ nhập hàng đến bán hàng. Khi biết đến Zump
    việc quản lý các khoản chi thu của cửa hàng trở nên trực quan và dễ dàng hơn, không phải dành nhiều thời gian để tổng hợp các hóa đơn giấy nữa.`,
    avatar: CustomerAvatar2,
    name: 'Thái Hoàng Thịnh',
    role: 'Chủ quán nước',
  },
  {
    point: 5,
    review: `Từ khi gặp được Zump từ một người không có nhiều kiến thức về công nghệ như tôi cũng có thể quản lý quán thông qua điện thoại, giúp tôi
    thuận lợi hơn trong việc quản lý khi không có mặt ở quán`,
    avatar: CustomerAvatar3,
    name: 'Nguyễn Lê Việt Hoàng',
    role: 'Chủ quán lẩu nướng',
  },
  {
    point: 5,
    review: `Zump thực sự là một đối tác đáng tin cậy cho quán ăn của chúng tôi. Tính năng quản lý thông minh và khả năng tương tác linh hoạt đã giúp
    chúng tôi tăng cường hiệu suất làm việc và cung cấp dịch vụ tốt nhất cho khách hàng của mình`,
    avatar: CustomerAvatar4,
    name: 'Nguyễn Minh Tuấn',
    role: 'Chủ quán nhậu',
  },
  {
    point: 5,
    review: `Tôi biết đến Zump từ những ngày đầu tiên làm quán. Zump đã giúp tôi tối ưu hơn quy trình phục vụ khách hàng, qua đó nhận được
    sự hài lòng và đánh giá cao từ các khách hàng.`,
    avatar: CustomerAvatar1,
    name: 'Nguyễn Bách Việt',
    role: 'Chủ quán ăn',
  },
  {
    point: 5,
    review: `Trước khi biết đến Zump tôi luôn phải đau đầu mỗi tuần với các hóa đơn giấy từ nhập hàng đến bán hàng. Khi biết đến Zump
    việc quản lý các khoản chi thu của cửa hàng trở nên trực quan và dễ dàng hơn, không phải dành nhiều thời gian để tổng hợp các hóa đơn giấy nữa.`,
    avatar: CustomerAvatar2,
    name: 'Thái Hoàng Thịnh',
    role: 'Chủ quán nước',
  },
  {
    point: 5,
    review: `Từ khi gặp được Zump từ một người không có nhiều kiến thức về công nghệ như tôi cũng có thể quản lý quán thông qua điện thoại, giúp tôi
    thuận lợi hơn trong việc quản lý khi không có mặt ở quán`,
    avatar: CustomerAvatar3,
    name: 'Nguyễn Lê Việt Hoàng',
    role: 'Chủ quán lẩu nướng',
  },
  {
    point: 5,
    review: `Zump thực sự là một đối tác đáng tin cậy cho quán ăn của chúng tôi. Tính năng quản lý thông minh và khả năng tương tác linh hoạt đã giúp
    chúng tôi tăng cường hiệu suất làm việc và cung cấp dịch vụ tốt nhất cho khách hàng của mình`,
    avatar: CustomerAvatar4,
    name: 'Nguyễn Minh Tuấn',
    role: 'Chủ quán nhậu',
  },
];

export const TestimonialsSection = () => {
  return (
    <section
      id="testimonials"
      className="section overflow-hidden bg-gradient-to-br from-white to-primary-100"
    >
      <Title
        tag="Reviews"
        title="Khách hàng nói về Zump"
        description="Zump rất vui khi nhận được những phản hồi tích cực từ khách hàng!"
        className="p-8"
      />
      <Swiper
        modules={[Autoplay]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        freeMode
        loop
        centeredSlides
        slidesPerView="auto"
        speed={700}
        className="w-full p-8"
      >
        {reviews.map((review, i) => (
          <SwiperSlide key={i} className="!max-w-96 p-5">
            <CardTestimionial {...review} />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};
