import { Title } from '../common/Title';
import AppStore from '../widgets/AppStore';
import GooglePlay from '../widgets/GooglePlay';

export const CTASection = () => {
  return (
    <section
      id="cta"
      className="section bg-gradient-to-br from-white to-primary-100 text-center"
    >
      <div className="section-container flex flex-col items-center gap-5">
        <Title
          title="Miễn phí 3 tháng với đầy đủ tính năng cho cửa hàng đầu tiên của bạn"
          description="Hãy trải nghiệm Zump ngay hôm nay!"
          className="max-w-3xl p-8"
        />
        <div className="flex gap-5">
          <AppStore />
          <GooglePlay />
        </div>
      </div>
    </section>
  );
};
