import Image from 'next/image';

import { CheckedImage } from '@/assets';
import type { PricingPlan } from '@/config/pricingPlans';
import { currency } from '@/utils/Parsers';

interface PricingPlanProp {
  title: string;
  features: string[];
  pricingPlans: PricingPlan[];
}

export const PricingPlans = ({
  title,
  features,
  pricingPlans,
}: PricingPlanProp) => {
  return (
    <div className="flex w-full flex-col justify-between gap-5 rounded-3xl border-4 border-gray-100 bg-white p-5 shadow-2xl shadow-primary-100/40 transition-shadow md:flex-row xl:p-10 2xl:mx-10">
      <div className="flex w-full flex-col gap-5 py-2 xl:items-start">
        <h2>{title}</h2>
        <ul>
          {features.map((item, key) => (
            <li key={key} className="flex items-start gap-4 py-2">
              <Image src={CheckedImage} alt="check" />
              <p className="text-left">{item}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex w-full flex-col items-center gap-3">
        {pricingPlans.map((item, key) => (
          <div
            key={key}
            className="flex w-full cursor-default flex-col rounded-lg border border-gray-200 p-5 transition-all hover:scale-105 hover:shadow-md"
          >
            <h4 className="text-left">{item.name}</h4>
            <div className="justify-between gap-x-10 sm:flex-row sm:items-end">
              <div>
                {item.original_price !== item.price && (
                  <p className="text-left text-sm">{`Tiết kiệm: ${currency(
                    +item.original_price - +item.price
                  )} ₫`}</p>
                )}
              </div>
              <div>
                {item.original_price !== item.price && (
                  <p className="text-left text-sm">{`Giá gốc: ${currency(
                    +item.original_price
                  )} ₫`}</p>
                )}
              </div>
            </div>
            <div className="mt-4 flex flex-col justify-between gap-x-10 sm:flex-row sm:items-end">
              <h2 className="grow text-right">{`${currency(
                +item.price
              )} ₫`}</h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
