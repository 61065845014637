import type { StaticImageData } from 'next/image';
import Link from 'next/link';
import React from 'react';

export interface HeaderLogoProps {
  customLogo?: string | StaticImageData;
  customName?: string;
}

export default function HeaderLogo({ customLogo }: HeaderLogoProps) {
  return (
    <Link href="/" className="flex flex-row items-center text-inherit" shallow>
      <h1 className="font-extrabold text-primary-500 xl:text-5xl">Zump</h1>
      {/* <Image
        src={customLogo ?? LogoImage}
        alt="logo"
        className="h-10 w-auto rounded-lg object-cover py-2"
        priority={true}
        crossOrigin="anonymous"
      /> */}
    </Link>
  );
}
