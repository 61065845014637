import { Title } from '@/components/common/Title';
import { basePlans } from '@/config/pricingPlans';

import { PricingPlans } from '../common/PricingPlans';

export const PricingSection = () => {
  return (
    <section id="pricing" className="section section-container">
      <Title
        tag="Pricing"
        title="Bảng báo giá dịch vụ Zump"
        description="Nâng cao trải nghiệm khách hàng của bạn!"
        className="mb-16 text-center lg:text-left"
      />
      <div className="flex w-full flex-col lg:flex-row">
        <PricingPlans
          title="Gói Base"
          features={[
            'Thiết lập cửa hàng và quản lý dễ dàng.',
            'Thêm nhiều nhân viên và phân quyền quản lý.',
            'Đồng bộ đơn hàng tối ưu hóa quy trình.',
            'Thanh toán quét mã QR chuyển khoản linh hoạt.',
            'Chia sẻ hóa đơn online cho khách hàng.',
            'Trang bán hàng online dễ dàng truy cập.',
            'Thống kê chi tiết giúp làm chủ hoạt động kinh doanh.',
          ]}
          pricingPlans={basePlans}
        />
      </div>
    </section>
  );
};
