import {
  ArrowRight,
  BeerStein,
  BowlSteam,
  Champagne,
  Coffee,
  CookingPot,
  Hamburger,
  IntersectThree,
  Popcorn,
  Shrimp,
} from '@phosphor-icons/react';
import Link from 'next/link';
import type { ReactElement } from 'react';

import { Title } from '../common/Title';

interface BusinessProp {
  title: string;
  desc: string;
  icon: ReactElement;
  url: string;
}

const businessTypes: BusinessProp[] = [
  {
    title: 'Quán ăn truyền thống',
    desc: 'Từ các mô hình vừa và nhỏ như quán ăn, quán cà phê, cho đến các mô hình lớn như nhà hàng, buffet, chuỗi F&B',
    icon: <BowlSteam size={32} weight="regular" />,
    url: '/',
  },
  {
    title: 'Quán lẩu - nhậu',
    desc: 'Từ các mô hình vừa và nhỏ như quán ăn, quán cà phê, cho đến các mô hình lớn như nhà hàng, buffet, chuỗi F&B',
    icon: <BeerStein size={32} weight="regular" />,
    url: '/',
  },
  {
    title: 'Quán ốc - ăn vặt',
    desc: 'Từ các mô hình vừa và nhỏ như quán ăn, quán cà phê, cho đến các mô hình lớn như nhà hàng, buffet, chuỗi F&B',
    icon: <Hamburger size={32} weight="regular" />,
    url: '/',
  },
  {
    title: 'Quán sinh tố - trà sữa',
    desc: 'Từ các mô hình vừa và nhỏ như quán ăn, quán cà phê, cho đến các mô hình lớn như nhà hàng, buffet, chuỗi F&B',
    icon: <Champagne size={32} weight="regular" />,
    url: '/',
  },
  {
    title: 'Quán Cà phê',
    desc: 'Từ các mô hình vừa và nhỏ như quán ăn, quán cà phê, cho đến các mô hình lớn như nhà hàng, buffet, chuỗi F&B',
    icon: <Coffee size={32} weight="regular" />,
    url: '/',
  },
  {
    title: 'Quán bán mang đi',
    desc: 'Từ các mô hình vừa và nhỏ như quán ăn, quán cà phê, cho đến các mô hình lớn như nhà hàng, buffet, chuỗi F&B',
    icon: <Popcorn size={32} weight="regular" />,
    url: '/',
  },
  {
    title: 'Nhà hàng truyền thống',
    desc: 'Từ các mô hình vừa và nhỏ như quán ăn, quán cà phê, cho đến các mô hình lớn như nhà hàng, buffet, chuỗi F&B',
    icon: <CookingPot size={32} weight="regular" />,
    url: '/',
  },
  {
    title: 'Nhà hàng Buffet',
    desc: 'Từ các mô hình vừa và nhỏ như quán ăn, quán cà phê, cho đến các mô hình lớn như nhà hàng, buffet, chuỗi F&B',
    icon: <Shrimp size={32} weight="regular" />,
    url: '/',
  },
  {
    title: 'Mô hình chuỗi',
    desc: 'Từ các mô hình vừa và hình lớn như nhà hàng, buffet, chuỗi F&B',
    icon: <IntersectThree size={32} weight="regular" />,
    url: '/',
  },
];

export const SolutionSection = () => {
  return (
    <section id="solutions" className="section section-container max-sm:px-0">
      <Title
        title="Zump đáp ứng cho mọi mô hình kinh doanh trong lĩnh vực F&B"
        description="Từ các mô hình vừa và nhỏ như quán ăn, quán cà phê, cho đến các mô hình lớn như nhà hàng, buffet, chuỗi F&B"
        className="m-auto max-w-3xl text-center max-sm:px-8"
      />
      <div className="mx-auto mt-20 grid grid-cols-3 gap-2 max-sm:px-2 md:gap-5">
        {businessTypes.map((business, i) => (
          <Link
            key={i}
            href={business.url}
            className="group flex max-w-80 flex-col gap-5 rounded-2xl border-2 p-2 shadow transition-colors will-change-color hover:border-primary-400 md:p-5 md:shadow-lg"
          >
            <div className="flex grow flex-col gap-1 md:gap-5">
              <div className="mx-auto size-16 rounded-lg bg-none p-4 text-primary-400 transition-colors will-change-color md:mx-0 md:bg-primary-50 md:group-hover:bg-primary-400 md:group-hover:text-white">
                {business.icon}
              </div>
              <h3 className="max-sm:text-sm md:text-left">{business.title}</h3>
              <p className="hidden md:block md:text-left">{business.desc}</p>
            </div>
            <div className="hidden items-center gap-2 text-primary-500 md:flex">
              <span className="text-primary-500">Tìm hiểu thêm</span>
              <ArrowRight />
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};
